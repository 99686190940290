.page-container {
  display: flex;
  width: 100vw;
  overflow: hidden;
  height: 90vh;
  position: absolute;
  bottom: 0;
}

.media-container {
  display: flex;
  width: 100vw;
  transition-duration: 1s;
  justify-content: space-between;
  align-items: center;
}

#primary {
  background-image: radial-gradient(
    circle farthest-corner at 25% 50%,
    #c3d3e5,
    #7d9aaa 15%,
    #485463 35%,
    #03202f 60%
  );
}

.media-container.is-visible .switch-panel {
  opacity: 1;
}

.hero-image-container {
  width: 50vw;
}

.hero-image-container img {
  position: absolute;
  height: 100%;
  bottom: 0;
  left: 0;
}

.list-container {
  width: 50vw;
}

.list-container ul {
  list-style: none;
  text-align: start;
  display: flex;
  flex-flow: column;
  width: 85%;
  height: 80vh;
  justify-content: center;
}

li {
  padding: 1vw 3vw;
  font-size: 2vh;
  overflow: hidden;
}

li::before {
  content: "\25A0";
  color: #d52b1e;
  /* background-color: #d52b1e; */
  display: inline-block;
  width: 4vh;
  margin: 1vh 0vw -1vh -4vh;
  font-size: 4vh;
}

#footnote {
  font-size: 1vw;
  margin-bottom: 3vh;
}

.science-image-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 50vw;
  height: auto;
  object-fit: cover;
}

.hide {
  transform: translateX(-100%);
}

.switch-panel {
  transition: all 0.2s;
  opacity: 0;
  background: none;
  overflow: hidden;
  outline: none;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  scale: 75%;
}

.switch-panel img {
  max-width: 10vh;
  max-height: 10vh;
}
.switch-panel.right {
  position: fixed;
  bottom: 5vh;
  right: 5vh;
}

.switch-panel.left {
  position: fixed;
  bottom: 5vh;
  left: 5vh;
}
