@keyframes fadeInRight {
  from {
    width: 10%;
  }
  to {
    width: 80%;
  }
}

.title-slide {
  position: fixed;
  top:0;
  height: 100vh;
  width: 100vw;
  justify-content: center;
  display: flex;
  flex-flow: column;
  /* flex-grow: 1; */
  background-image: url("https://storage.googleapis.com/purina-virtual-experience/04-advancing-brain-health/images/nodes.png");
  background-repeat:round;
  z-index: 1;
}

.title-slide h1 {
  position: fixed;
width: 66vw;  
text-align: center;
}

.title-slide h1 hr {
  z-index: -1;
  background-color: #d52b1e;
  border: none;
  height: 0.5vw;
  width: 80%;
  animation: fadeInRight 3s backwards;
  margin-top: 2vh;
}

.central-media-container {
  display: flex;
  width: 100vw;
  align-items: stretch;
}

.media-start-button {
  display: flex;
  margin: auto;
  border: transparent solid 1px;
  background-color: #d52b1e;
  color: white;
  position: relative;
  top: 15vh;
  width: 18vw;
  height: 7vh;
  align-items: center;
  justify-content: center;
  font-size: 2vw;
  border-radius: 5px;
}

.media-start-button:hover {
  cursor: pointer;
  border: #d52b1e solid 1px;
  background-color: white;
  color: #d52b1e;
}